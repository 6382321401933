export const Color = {
  // Accent and Primary
  AccentBlue: '#5d87b7',
  AccentBlueGreen: '#53a8a6',
  AccentBlueLight: '#76d7ff',
  AccentDullGreen: '#408c3c',
  AccentPurple: '#695c98',
  AccentYellowGreen: '#a7be70',
  Main: '#47b881',
  Primary: '#0788de',
  // Background
  BackgroundAlertError: '#f6e3e2',
  BackgroundAlertWarning: '#fef680',
  BackgroundContent: '#fff',
  BackgroundDSPHeader: '#47b881',
  BackgroundDefault: '#fcfcfa',
  BackgroundError: '#ec4c47',
  BackgroundHover: 'rgba(67, 90, 111, .04)',
  BackgroundSSPHeader: '#16ade4',
  BackgroundWarning: '#ffba70',
  // Border
  BorderDark: '#c6c9cd',
  BorderDefault: '#e4e7eb',
  // Creative
  CreativeContent: '#47b881',
  CreativeNative: '#5553f7',
  CreativePMPPD: '#9392fa',
  CreativeRTB: '#f79653',
  CreativeRemnant: '#ec4c47',
  // Icon
  IconCaution: '#d9822b',
  IconDefault: '#66788a',
  IconWarning: '#ec4c47',
  // Text
  TextCaution: '#95591e',
  TextDefault: '#425a70',
  TextDisabled: 'rgba(66, 90, 112, .3)',
  TextInfo: '#084b8a',
  TextInverse: '#fff',
  TextLight: '#66788a',
  TextLink: '#1070ca',
  TextWarning: '#bf0e08',
  // Cell and Header
  CurrentDateCell: '#1A73E8',
  // Other
  AvatarBg: '#5d87b7',
  UnschedulableColor: '#ec4c4780',
};

export const Font = { SizeDefault: '14px' };

const BORDER_STYLE_DEFAULT = '1px solid';

export const BoxModel = {
  BorderDefault: `${BORDER_STYLE_DEFAULT} ${Color.BorderDefault}`,
  BorderRadius: '4px',
  BorderStyleDefault: (color?: string) => `${BORDER_STYLE_DEFAULT} ${color ?? ''}`,
  MarginMain: '0 40px 128px',
  PaddingPaper: '16px 16px 24px',
};

export const ZIndex = {
  DateModal: '41',
  ModalOverlay: '40',
  PageHeader: '30',
  SideMenu: '25',
  Subtitle: '12',
  TableHeader: '10',
  TableHeaderFixed: '11',
};

export const Shadow = {
  Footer: '1px 0px 4px rgba(0, 0, 0, 0.25)',
  Paper: '0px 3px 5px rgba(0, 0, 0, 0.25)',
  TableHeader: '0 2px 4px rgba(0, 0, 0, 0.25)',
  TopLeftCorner: '1px 1px 4px rgba(0, 0, 0, 0.25)',
};
